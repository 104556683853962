<!-- 설비 인벤토리 상세 (EquipInfoDetail) -->
<template>
    <div :class="type ? 'global-detail-container' : 'detail-container'">
        <!-- 1. 데이터 없음 표시 영역 -->
        <div class="detail-placeholder" v-if="!detailInfo">
            <span class="detail-notification-icon">
                <i class="fas fa-external-link-alt"></i>
            </span>
            <span class="detail-notification-text">설비 인벤토리를 선택하세요.</span>
        </div>

        <!-- 2.상세 정보 영역 -->
        <div style="height: calc(100% - 70px)" v-if="detailInfo">
            <!-- 2.상세 정보 제목 영역 -->
            <div class="detail-header" v-if="detailInfo">
                <div class="col detail-title">
                    <span class="title">{{ viewTitle }}</span>
                    <span class="badge title1" v-if="detailInfo"> {{ detailInfo.equipName }} </span>
                    <span class="badge title2" v-if="detailInfo"> {{ detailInfo.equipIdx }} </span>
                    <span class="badge others" v-if="detailInfo"> {{ deviceName }} </span>
                </div>
                <div class="detail-control" v-if="!isNewMode && !type">
                    <b-form-checkbox switch v-model="isEditMode" size="lg" />
                </div>
            </div>

            <div class="detail-content" v-if="detailInfo">
                <BaseInfoPanel :detailInfo="detailInfo" :mode="mode" :type="equipType"
                    :moduleList="moduleList" @getModuleList="getModuleList" @clearData="clearData" />

                <!-- 서브 패널 영역 -->
                <!-- 
                <div
                    style="padding-left:16px; padding-right:16px; width:100%; height:calc(100% - 300px); overflow: hidden">
                    <b-card no-body>
                        <b-tabs card>
                            <b-tab title="계측 항목">
                                <IoPropListPanel :detailInfo="detailInfo" :mode="mode" />
                            </b-tab>
                            <b-tab title="계산 항목">
                                <CalcPropListPanel :detailInfo="detailInfo" :mode="mode" />
                            </b-tab>
                            <b-tab title="사용자정의 항목">
                                <UserPropListPanel :detailInfo="detailInfo" :mode="mode" />
                            </b-tab>
                        </b-tabs>
                    </b-card>
                </div>
                -->
            </div>
        </div>

        <!-- 4.하부 버튼 영역 -->
        <div class="detail-bottom" v-if="detailInfo && !type">
            <div v-if="detailInfo" class="detail-bottom-info">
                <span>{{ `작성자: ${detailInfo.regWorker ? detailInfo.regWorker : "-"}` }}</span>
                <span>{{ `작성일자: ${detailInfo.regDate ?? "-"}` }}</span>
                <span>{{ `수정일자: ${detailInfo.modDate ?? "-"}` }}</span>
            </div>

            <div class="detail-bottom-buttons mt-2">
                <button type="button" class="btn btn-primary detail-btn" v-if="isEditMode" style="flex: 1"
                    @click="handleSave(detailInfo, isEditMode)">저장</button>
                <button type="button" class="btn btn-secondary detail-btn" v-if="detailInfo" style="flex: 1"
                    @click="handleClose($event)">닫기</button>
            </div>
        </div>

    </div>
</template>

<script>
import BaseInfoPanel from "./panels/BaseInfoPanel.vue";

import backEndApi from "@src/api/backEndApi";
import moment from "moment";


export default {
    components: {
        BaseInfoPanel,
    },
    props: ['title', 'equipType', 'equipIdx', 'type'],
    async created() {
    },
    mounted() {
    },
    data() {
        return {
            detailInfo: null,
            mode: 'view',            // 편집모드 : edit/view

            moduleList: null,
        };
    },
    computed: {
        isEditMode: {
            get() {
                return this.mode == 'edit' || this.mode == 'new';
            },
            set(value) {
                this.mode = value ? 'edit' : 'view';
            },
        },
        isNewMode() {
            return this.mode === 'new';
        },
        viewTitle() {
            return this.title
        },
        deviceName() {
            if (!this.moduleList) return '';

            return this.moduleList?.find(module => module.moduleIdx == this.detailInfo.moduleIdx)?.deviceName ?? '';
        },
        equipTypeInfo() {
            try {
                return this.$store.state.equipTypeList.find(item => item.equipType == this.equipType);
            } catch (e) {
                console.error(e)
                return [];
            }
        },
        filterSystemType() {
            return this.$store.state.systemTypeList.find(type => type.allowedEquipTypes.some(allow => allow.equipType == this.equipType))
        },
    },
    watch: {
        // equipIdx(newVal) {

        //     if (!this.equipIdx) {
        //         // clear detailInfo
        //         this.clearData();
        //     } else {
        //         if(newVal || this.equipIdx == newVal)
        //         // load detailInfo
        //         this.loadData();
        //     }
        // }
    },
    methods: {
        // list-Detail Common Functions

        async notifyCancelEdit() {
            // return true : Edit Cancel & Proceed Next Step
            //        false: Stop.
            if (this.isEditMode) {
                const result = await this.alertConfirmWarning("편집중인 내용을 무시하고 종료할까요?");
                if (result.value) return true;
                else return false;
            }

            return true;
        },

        // list-Detail Data Functions
        newData() {
            this.mode = 'new';
            this.detailInfo = {
                equipName: null,
                equipCategory: null,
                equipType: null,
                equipUseType: null,
                zoneIdx: null,
                moduleIdx: null,
                enabled: 'Y',
                description: null,
                ioProps: [],
                calcProps: [],
                userProps: [],
                ptMaps: [],
            };

            return this.detailInfo;
        },

        async loadData(reload) {
            const that = this;


            try {
                let result = await backEndApi.equips.getEquipDetailList(that.equipIdx);

                if (result.status == 200) {
                    result.data.regDate = result.data.regDate ? moment(result.data.regDate).format("YYYY-MM-DD HH:mm:ss") : null;
                    result.data.modDate = result.data.modDate ? moment(result.data.modDate).format("YYYY-MM-DD HH:mm:ss") : null;

                    if (result.moduleIdx) {
                        result.data.ioProps.forEach(ioProp => {
                            let foundEquip = that.equipTypeInfo.ioProps.find(item => item.propCode === ioProp.propCode)
                            if (foundEquip) {
                                that.$set(ioProp, "propName", foundEquip.propName);
                                that.$set(ioProp, "unitType", foundEquip.unitType);
                                that.$set(ioProp, "unit", foundEquip.unit);

                            }
                        })
                        result.data.calcProps.forEach(calcProp => {
                            let foundEquip = that.equipTypeInfo.calcProps.find(item => item.propCode === calcProp.propCode)
                            if (foundEquip) {
                                that.$set(calcProp, "propName", foundEquip.propName);
                                that.$set(calcProp, "unitType", foundEquip.unitType);
                                that.$set(calcProp, "unit", foundEquip.unit);
                                that.$set(calcProp, "expression", foundEquip.expression);
                            }
                        })
                        result.data.userProps.forEach(userProp => {
                            let foundEquip = that.equipTypeInfo.userProps.find(item => item.propCode === userProp.propCode)
                            if (foundEquip) {
                                that.$set(userProp, "propName", foundEquip.propName);
                                that.$set(userProp, "unitType", foundEquip.unitType);
                                that.$set(userProp, "unit", foundEquip.unit);
                                that.$set(userProp, "expression", foundEquip.expression);
                            }
                        })

                        if(!reload && this.isEmpty(this.type)) {
                            if (this.filterSystemType.sumAvail == 'N') {
                                await that.getModuleList(result.data.equipType, result.data.moduleIdx, result.data.deviceIdx);
                            }
                        }
                    } else {
                        if(!reload && this.isEmpty(this.type)) {
                            if (this.filterSystemType.sumAvail == 'N') {
                                await that.getModuleList(result.data.equipType);
                            }
                        }
                    }

                    that.detailInfo = { ...result.data };
                    that.mode = 'view';

                }

            } catch (e) {
                that.alertNoti("데이터를 조회할 수 없습니다.");
                console.error(e);
            }
        },

        clearData() {

            this.detailInfo = null;
            this.mode = "view";

            this.$forceUpdate();
        },

        async saveData(data) {

            if (this.isEmpty(data.equipName)) {
                this.alertNoti("설비명은 필수 입력 항목입니다.");
                return;
            }
            if (data.ptMaps.length > 0) {
                data.ptMaps.forEach(item => {
                    if(item.ptAddr) delete item.ptAddr;
                    if(item.ptName) delete item.ptName;
                    if(item.unit) delete item.unit;
                })
            }

            try {
                let result = await backEndApi.equips.saveEquip(data);
                if (result.status == 200) {
                    await this.alertNoti("데이터를 저장하였습니다..");

                    if (this.isNewMode) {
                        await this.$emit('data-updated', 'insert', this.detailInfo);
                    }
                    else {
                        const reload = true;
                        this.$emit('data-updated', 'update', this.detailInfo);
                        await this.loadData(reload)
                    }

                    this.mode = 'view';
                }
            } catch (e) {
                this.alertNoti("데이터를 저장할 수 없습니다.");
                console.error(e);
            }
        },

        async deleteData() {
            const result = await this.alertConfirmWarning("현재 선택된 데이터를 삭제할까요?");
            if (!result.value) return;

            const deletedEquipIdx = this.detailInfo.equipIdx;


            try {
                const equipIdx = [deletedEquipIdx];
                const result = await backEndApi.equips.deleteEquipInfo(equipIdx);
                console.log(result)

                this.clearData();
                this.$emit('data-updated', 'delete', deletedEquipIdx);

                this.alertNoti("데이터를 삭제하였습니다.");
            } catch (e) {
                this.alertNoti("데이터를 삭제할 수 없습니다.");
                console.error(e);
            }
        },

        getData() {
            return this.detailInfo;
        },

        // Private Event Handling Functions        
        async handleSave(info) {
            await this.saveData(info);
        },

        async handleClose(event) {

            const result = await this.notifyCancelEdit();
            if (!result) {
                event && event.preventDefault();
                return false;
            }
            // this.toggleEditMode();  
            this.mode = 'view';
            this.clearData();
            this.$emit('closed', this.commonCode);

            return true;
        },

        async getModuleList(equipType, moduleIdx, deviceIdx) {
            if (this.mode !== 'view') {
                try {
                    let result = await backEndApi.equips.getModuleList(equipType, "");
                    if (result.data) {
                        this.moduleList = result.data.filter(module => module.occupiedEquipIdx === null);
                        this.moduleList = this.moduleList.sort((a, b) => {
                            if (a.deviceName < b.deviceName) return -1;
                            if (a.deviceName > b.deviceName) return 1;

                            if (a.moduleName < b.moduleName) return -1;
                            if (a.moduleName > b.moduleName) return 1;
                        });
                    }
                } catch (e) {
                    console.error(e);
                }
            } else {
                const showOccufiedEquip = "";
                const moduleIdx = null;
                try {
                    let result = await backEndApi.equips.getModuleList(equipType, showOccufiedEquip, moduleIdx, deviceIdx);
                    if (result.data) {
                        this.moduleList = result.data
                    }
                } catch (e) {
                    console.error(e);
                }
            }
        },
    },
}

</script>

<style scoped>

.detail-container .card .tabs .tab-content .card-body {
    padding-top: 0px;
}

.detail-notification-icon {
    padding: 1rem;
    color: #444;
    font-size: 48px;
}

.detail-notification-text {
    font-size: 15px;
    font-weight: bold;
}

.detail-container {
    height: 100%;
    width: 100%;
    padding: 1rem 1rem 1rem 1rem;
    overflow: hidden;
    
    display: flex;
    flex-direction: column;
    
    border-radius: 10px;
    font-size: 12px;
}

.global-detail-container {
    width: 100%;
    padding: 1rem 1rem 1rem 1rem;
    overflow-y: auto;
    
    display: flex;
    flex-direction: column;
    
    border-radius: 10px;
    font-size: 12px;
}

.detail-placeholder {
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.detail-header {
    margin-bottom: 0px !important;
    padding: 8px 16px 8px 16px;
    overflow: hidden;

    display: flex;
    justify-content: space-between;
    align-items: center;
    
    border-radius: 6px 6px 0px 0px;
    border: solid #ececec 1px;
    border-bottom: 1px solid #ccc;
    background-color: #f1f1f1;
    font-weight: bold;
}

.detail-header .detail-title {
    padding: 0 !important;

    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.detail-content {
    height: Calc(100% - 50px);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
    padding: 20px;
    overflow: auto;
    box-sizing: border-box;

    background-color: white;
    border: solid #ececec 1px;
    border-radius: 0px 0px 6px 6px;
}

.detail-itemview {
    /* height: 200px; */
    width: 100%;
    padding: 1rem 1rem 1rem 1rem;
    justify-content: center;
    align-items: center;
    /* overflow-y: scroll; */
    border: solid #ececec 1px;
    /* border: solid red 1px; */
    border-radius: 0px 0px 6px 6px;
    /* padding: 1rem 1rem; */
}

.detail-control {
    margin-bottom: 8px !important;
    display: flex;
    justify-content: flex-end;
}

.detail-menu {
    font-size: 0.8rem;
    font-weight: bold;
}

.detail-bottom {
    margin-top: auto;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 40 px;
}

.detail-bottom-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.detail-btn {
    /* height: 50px; */
    flex: 1;
    margin: 0px 4px 0px 4px;
}

.detail-bottom-info {
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 4px 8px 4px 8px;
}

.title-icon {
    font-size: 1rem !important;
}

.input-form {
    width: 100%;
    /* 부모 요소에 꽉 맞춤 */
    box-sizing: border-box;
    /* 입력 폼 요소의 패딩과 보더를 포함하여 크기를 계산 */
}

.input-form input {
    width: 100%;
    /* 입력 요소가 부모 요소에 꽉 맞춤 */
    padding: 10px;
    /* 선택적으로 패딩 설정 */
    margin-bottom: 10px;
    /* 선택적으로 아래쪽 여백 추가 */
}


.collapsed>.when-opened,
:not(.collapsed)>.when-closed {
    display: none;
}


.title {
    /* font-size: 14px; */
    font-weight: 600;
    margin-right: 20px;
}

.badge {
    font-size: 12px;
    font-weight: 500;
    margin-right: 4px;
    display: inline-block;
    min-width: 20px;
    padding: 0px 10px 0px 10px;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    /* background-color: #777; */
    line-height: 22px;
    border-radius: 8px;
}

.badge.title1 {
    background-color: #FF6D00;
    color: #fff;
}

.badge.title2 {
    background-color: #546E7A;
    color: #fff;
}

.badge.others {
    background-color: #CFD8DC;
    color: #000;
}

.status-icon {
    font-size: 16px;
    color: #546E7A;
    background-color: #ECEFF1;
    border: solid #B0BEC5 1px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.2rem;
    transition: background-color 0.5s;
    width: 2rem;
    height: 2rem;
}

.status-icon:hover {
    /* color: #546E7A; */
    background-color: #B0BEC5;
}

.status-icon.active {
    color: #ECEFF1;
    background-color: #546E7A;
}
</style>