<template>
    <div v-if="detailInfo" class="detail-container">
        <div class="detail-content">
            <div class="row">
                <div class="col-md-7">
                    <label for="ptAddr">연결된 관제점</label>
                    <input type="text" class="form-control" id="ptAddr" disabled :value="detailInfo.ptAddr ?? '-'">
                </div>
                <div class="col-md-2 p-0">
                    <label for="ptIdx">관제점 ID</label>
                    <input v-model="detailInfo.ptIdx" type="text" class="form-control" id="ptIdx" disabled>
                </div>
                <div class="col-md-3" style="display: flex; flex: 1; align-items: end; justify-content: end;">
                    <button v-b-toggle.equipCalcPropPtSearch type="button" class="btn btn-primary" style="flex: 1;"
                        :disabled="!isEditMode">관제점 선택</button>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-6 mt-2">
                    <label for="propCode">계산 속성</label>
                    <input v-model="detailInfo.propCode" type="text" class="form-control" id="propCode"
                        :disabled="!isEditMode">
                </div>
                <div class="form-group col-md-6 mt-2">
                    <label for="propName">계산 속성명</label>
                    <input v-model="detailInfo.propName" type="text" class="form-control" id="propName"
                        :disabled="!isEditMode">
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label for="unitType">단위 유형</label>
                    <select v-model="detailInfo.unitType" id="unitType" class="form-control" :disabled="!isEditMode">
                        <option :value="null">선택</option>
                        <option v-for="(type, index) in $store.state.unitTypes" :key="index" :value="type.value">{{
                            type.text }}</option>
                    </select>
                </div>
                <div class="form-group col-md-6">
                    <label for="unit">단위</label>
                    <select v-model="detailInfo.unit" id="unit" class="form-control" :disabled="!isEditMode">
                        <option :value="null">선택</option>
                        <option v-for="(code) in $store.state.unitCodes[detailInfo.unitType]" :key="code.value"
                            :value="code.value" id="unit">
                            {{ code.text }}
                        </option>
                    </select>
                </div>
                <!-- :disabled="!isEditMode || detailInfo.calcMethod !== 'Equip'" -->
                <div class="from-group col-md-12">
                    <label for="expression">계산식</label>
                    <!-- <textarea v-model="detailInfo.expression" class="form-control" id="expression" rows="5"
                        style="resize: none;" :disabled="!isEditMode"></textarea> -->
                       
                            <xe-codemirror 
                            :disabled="!isEditMode" 
                            :hintList="attributeCodeList" 
                            arrName='EQ' 
                            existExplanation=false>
                            </xe-codemirror>
                       
                </div>
            </div>
        </div>
        <b-sidebar v-model="sideBar" ref="equipCalcPropPtSearch" id="equipCalcPropPtSearch" bg-variant="white"
            backdrop-variant="secondary" width="50vw" @hidden="closeSidebar" no-header shadow right backdrop>
            <div v-if="sideBar" style="padding: 1rem; height: 100%">
                <SearchComponent :tableMaxHeight="'75vh'" :optionTableHeight="'67vh'" @select-point="selectPoint"
                    @close="closeSidebar" />
            </div>
        </b-sidebar>
    </div>
</template>

<script>
// import backEndApi from '@src/api/backEndApi';
import SearchComponent from '@src/views/component/v2.1/PointSearch/Search.vue';
import XeCodemirror from '@src/views/pointManagement/workflow/components/xeCodemirror.vue';

export default {
    components: {
        SearchComponent,
        XeCodemirror
    },
    props: ["mode","hintList"],
    data() {
        return {
            detailInfo: null,
            sideBar: false,
            attributeCodeList:null
        }
    },
    watch: {
     
       
    },
    computed: {
        isEditMode() {
            return this.mode !== 'view';
        },
    },
    created() {},
    mounted() { 
        /**자동완성 리스트 
         * @type {string[]}
         */
      this.attributeCodeList= this.hintList.map(item=>item.propName);
          
    },
    destroyed() {},
    methods: {
        showSideBar() {
            this.sideBar = true;
        },
        closeSidebar() {
            this.$refs.equipCalcPropPtSearch.hide();
            this.sideBar = false;
        },
        initData() {
            return {
                propCode: null,
                propName: null,
                dataSourceType: "Calc",
                ptIdx: null,
                expression: null,
                enabled: "Y",
                ptAddr: null,
                unitType: null,
                unit: null,
            }
        },
        setData(data) {
            if (data) {
                this.detailInfo = data;
            }
            else {
                this.detailInfo = this.initData();
            }
            // else return;
        },
        getData() {
            return this.detailInfo;
        },
        async selectPoint(data) {
            if (data) {
                this.detailInfo.ptIdx = data.ptIdx ? data.ptIdx : '-';
                this.detailInfo.ptAddr = data.ptAddr ? data.ptAddr : '-';
                this.detailInfo.unitType = data.unitLargeCode ? data.unitLargeCode : null;
                this.detailInfo.unit = (data.unitSmallCode !== "" && data.unitSmallCode) ? data.unitSmallCode : null;
            }
            await this.$refs.equipCalcPropPtSearch.hide();
        },
    },
}

</script>

<style scoped>
.detail-container {
    height: 100%;
    /* height: 400px; */
    /* width: 100%; */
    /* border-radius: 8px; */
    /* border: solid #eee 1px; */
    /* background-color: white; */
    font-size: 12px;
}

.detail-placeholder {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.detail-header {
    background-color: #fff;
    border-radius: 8px 8px 0px 0px;
    padding: 0.8rem 1rem;
    /* margin: 1rem 0rem; */
}

.detail-content {
    height: 100%;
    width: 100%;
    /* border-radius: 0px 0px 8px 8px; */
    /* border: solid #eee 1px; */
    /* padding: 1rem 1rem; */
    /* overflow-y: auto; */
}

.detail-bottom {
    display: flex;
    justify-content: space-between;
}

.btn-sm {
    height: 1.4rem !important;
    line-height: 0.9 !important;
}
</style>